import React, { useEffect } from "react";
import {
  Grid,
  Box,
  CircularProgress,
  FormControl,
} from "@material-ui/core";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import PersonIcon from "@mui/icons-material/Person";
import EnhancedEncryptionIcon from "@mui/icons-material/EnhancedEncryption";
import { useNavigate } from "react-router-dom";

import "./login.css";

export default function Index() {
  const [btnLoading] = React.useState(false);
  const [userId, setUserId] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [showAlert, setShowAlert] = React.useState(false);
  const [errorMessage] = React.useState(
    "Invalid User ID/Password"
  );
  const alphaNumericSpace = /^[a-zA-Z0-9]+$/;

  useEffect(() => {}, []);

  let navigate = useNavigate();
  const onLoginClick = () => {
    if (userId === "admin" && btoa(password) === process.env.REACT_APP_PASSWORD) {
      navigate("/merchants");
    } else {
      setShowAlert(true);
    }
  };

  return (
    <>
      <Button color="primary">Test</Button>
      <div className="loginDiv">
        <Grid container justifyContent="center">
          <Box className="loginBox">
            <Grid columns={{ xs: 1, sm: 1, md: 12 }} align="center">
              <Grid item xs align="center">
                <img src="Logo.jpeg" alt="Logo"></img>
              </Grid>
              <Grid item xs>
                {showAlert && <Alert severity="error">{errorMessage}</Alert>}
              </Grid>
              <Grid item xs align="center">
                <FormControl
                  fullWidth
                  variant="outlined"
                  style={{            
                    paddingBottom: "20px",
                    paddingTop: "30px",
                  }}
                >
                  <OutlinedInput
                    id="outlined-adornment-password"
                    startAdornment={
                      <InputAdornment position="start">
                        <PersonIcon fontSize="large" />
                      </InputAdornment>
                    }
                    placeholder="User ID"
                    fullWidth
                    value={userId}
                    onChange={(event) => {
                      const value = event.target.value;

                      if (!alphaNumericSpace.test(value) && value.length > 0) {
                        event.preventDefault();
                      } else {
                        setUserId(value);
                      }
                    }}
                  />
                  
                </FormControl>
              </Grid>

              <Grid item xs align="center">
                <FormControl
                  fullWidth
                  variant="outlined"
                  style={{
                    paddingBottom: "20px",
                  }}
                >
                  <OutlinedInput
                    startAdornment={
                      <InputAdornment position="start">
                        <EnhancedEncryptionIcon fontSize="large" />
                      </InputAdornment>
                    }
                    type="password"
                    placeholder="Password"
                    fullWidth
                    value={password}
                    onChange={(event) => {
                      const value = event.target.value;

                      if (!alphaNumericSpace.test(value) && value.length > 0) {
                        event.preventDefault();
                      } else {
                        setPassword(value);
                      }
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item align="right">
                <FormControl>
                  <Button
                    variant="contained"
                    size="large"
                    onClick={onLoginClick}
                    color="primary"
                    style={{ fontWeight: "bold" }}
                  >
                    {btnLoading && <CircularProgress size="1rem" />}
                    Login
                  </Button>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </div>
    </>
  );
}
