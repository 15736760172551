import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import { Grid, TextField } from "@material-ui/core";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import { addCoupons, GetMerchants, updateCoupon } from "../../API/service";
import "./coupons.css";
import Autocomplete from "@mui/material/Autocomplete";
import { createFilterOptions } from "@mui/material/Autocomplete";

export default function Edit(props) {
  const [open, setOpen] = React.useState(false);

  const [title, setTitle] = React.useState("");
  const [points, setPoints] = React.useState(0);
  const [couponsAllocated, setCouponsAllocated] = React.useState(0);
  const [description, setDescription] = React.useState("");
  const [benefitValue, setBenefitValue] = React.useState("");
  const [tnc, setTnC] = React.useState("");
  const [validity, setValidity] = React.useState(moment());
  const [merchants, setMerchants] = React.useState([]);
  const [merchantId, setMerchantId] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [btnDisabled, setBtnDisabled] = React.useState(true);
  const [showAlert, setShowAlert] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(
    "An error has occurred. Please try again later."
  );
  const [btnLoading, setBtnLoading] = React.useState(false);

  const numeric = /^[0-9]+$/;
  const alphaNumeric = /^[a-zA-Z .0-9]+$/;

  const filterOptions = createFilterOptions({
    ignoreCase: true,
  });

  useEffect(() => {
    setLoading(true);
    GetMerchants()
      .then((response) => {
        console.log("response received");
        if (response.message) {
          setMerchants(
            response.message?.map((merch) => {
              return { label: merch.name, id: merch.merchantId };
            })
          );
        } else {
          setMerchants([]);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (props.data != null) {
      setTitle(props.data.title);
      setPoints(props.data.points);
      setCouponsAllocated(props.data.couponsAllocated);
      setDescription(props.data.description);
      setBenefitValue(props.data.benefitValue);
      setTnC(props.data.termsConditions);
      setValidity(moment(props.data.validity));

      if (merchants) {
        merchants.forEach((merch) => {
          if (merch.id === props.data.merchantID) {
            setMerchantId(merch);
            return;
          }
        });
      }

      setBtnDisabled(false);
      setShowAlert(false);
      setBtnLoading(false);
      setOpen(true);
    }
  }, [props.data]);

  useEffect(() => {
    if (
      title?.length > 0 &&
      points.length > 0 &&
      couponsAllocated.length > 0 &&
      description.length > 0 &&
      benefitValue.length > 0 &&
      tnc.length > 0 &&
      merchantId !== 0
    ) {
      setBtnDisabled(false);
    }
  }, [
    points,
    couponsAllocated,
    description,
    benefitValue,
    tnc,
    validity,
    merchantId,
    title,
  ]);

  const handleOpen = () => {
    clearStateValues();
    setOpen(true);
  };

  const handleClose = () => {
    props.handleClose();
    setBtnDisabled(true);
    clearStateValues();
    setOpen(false);
  };

  const onButtonClick = async () => {
    const data = {
      title: title,
      points: points,
      couponsAllocated: couponsAllocated,
      description: description,
      benefitValue: benefitValue,
      termsConditions: tnc,
      validity: validity.format(),
      merchantID: merchantId.id,
    };
    console.log(data);
    setBtnLoading(true);
    setBtnDisabled(true);
    if (props.data == null) {
      await addCoupons(data)
        .then((response) => {
          if (response.code === 200) {
            handleClose();
          } else {
            setShowAlert(true);
            setBtnLoading(false);
            setBtnDisabled(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setErrorMessage(error);
          setShowAlert(true);
          setBtnLoading(false);
          setBtnDisabled(false);
        });
    } else {
      await updateCoupon(props.data.couponsId, data)
        .then((response) => {
          console.log(response);
          if (response.code === 200) {
            handleClose();
          } else {
            setShowAlert(true);
            setBtnLoading(false);
            setBtnDisabled(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setErrorMessage(error);
          setShowAlert(true);
          setBtnLoading(false);
          setBtnDisabled(false);
        });
    }
  };

  const clearStateValues = () => {
    setTitle("");
    setPoints(0);
    setCouponsAllocated(0);
    setDescription("");
    setBenefitValue("");
    setTnC("");
    setValidity(moment());
    setShowAlert(false);
    setBtnLoading(false);
    setMerchantId(0);
  };

  return (
    <div>
      <div className="button">
        <Button
          variant="contained"
          onClick={handleOpen}
          size="large"
          color="primary"
        >
          Create Coupon
        </Button>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        fullWidth
      >
        <Box className="boxstyle">
          <Grid container className="mod" alignItems="stretch" spacing={2}>
            <Grid item xs={12} align="center">
              <h2>Coupons Details</h2>
            </Grid>

            <Grid item xs={12}>
              {showAlert && <Alert severity="error">{errorMessage}</Alert>}
            </Grid>

            <Grid item xs={6}>
              <span className="modtext">Title</span>
              <TextField
                placeholder=""
                fullWidth
                required
                value={title}
                onChange={(event) => {
                  const value = event.target.value;
                  if (!alphaNumeric.test(value) && value.length > 0) {
                    event.preventDefault();
                  } else {
                    setTitle(value);
                  }
                }}
              />
            </Grid>
            <Grid item xs={loading ? 5 : 6}>
              <span className="modtext">Merchant</span>
              <Autocomplete
                disablePortal
                options={merchants}
                value={merchantId || null}
                onChange={(event, newValue) => {
                  setMerchantId(newValue);
                }}
                filterOptions={filterOptions}
                renderInput={(params) => <TextField {...params} fullWidth />}
                loading={loading}
              />
            </Grid>
            {loading && (
              <Grid item style={{ paddingTop: "20px" }}>
                {loading && <CircularProgress size="1.5rem" />}
              </Grid>
            )}

            <Grid item xs={12}>
              <span className="modtext">Description</span>
              <TextField
                placeholder=""
                fullWidth
                required
                value={description}
                onChange={(event) => {
                  const value = event.target.value;
                  setDescription(value);
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <span className="modtext">Points</span>
              <TextField
                placeholder=""
                fullWidth
                required
                value={points}
                onChange={(event) => {
                  const value = event.target.value;
                  if (!numeric.test(value) && value.length > 0) {
                    event.preventDefault();
                  } else {
                    setPoints(value);
                  }
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <span className="modtext">Coupons Allocated</span>
              <TextField
                placeholder=""
                fullWidth
                required
                value={couponsAllocated}
                onChange={(event) => {
                  const value = event.target.value;
                  if (!numeric.test(value) && value.length > 0) {
                    event.preventDefault();
                  } else {
                    setCouponsAllocated(value);
                  }
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <MobileDatePicker
                  inputFormat="DD/MM/yyyy"
                  value={validity}
                  onChange={(newValue) => {
                    setValidity(newValue);
                  }}
                  renderInput={(params) => (
                    <>
                      <span className="modtext">Validity</span>
                      <TextField fullWidth {...params} />
                    </>
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <span className="modtext">Benefit Value</span>
              <TextField
                placeholder=""
                fullWidth
                required
                value={benefitValue}
                onChange={(event) => {
                  const value = event.target.value;
                  if (!numeric.test(value) && value.length > 0) {
                    event.preventDefault();
                  } else {
                    setBenefitValue(value);
                  }
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <span className="modtext">Terms and Conditions</span>
              <TextField
                placeholder=""
                fullWidth
                required
                multiline
                maxRows={4}
                minRows={2}
                value={tnc}
                onChange={(event) => {
                  const value = event.target.value;
                  setTnC(value);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Button
                style={{ marginTop: "10px" }}
                variant="contained"
                onClick={onButtonClick}
                disabled={btnDisabled}
              >
                {btnLoading && <CircularProgress size="1rem" />}
                Save
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
