import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  TextField,
  CircularProgress,
  FormHelperText,
  Modal,
} from "@material-ui/core";
import Autocomplete from "@mui/material/Autocomplete";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  GetUsers,
  SendNotification,
  SendEmail,
  UploadImageToServer,
} from "../../API/service.jsx";
import "./messages.css";
import draftToHtml from "draftjs-to-html";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  heading: {
    color: "#77BBBB",
    fontWeight: "bold",
    alignItems: "left",
    textAlign: "left",
    marginLeft: "50px",
    fontSize: "30px",
  },
}));

const New = () => {
  const classes = useStyles();

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [type, setType] = React.useState("2");
  const [convertedContent, setConvertedContent] = useState(null);
  const [users, setUsers] = React.useState([]);
  const [emailUsers, setEmailUsers] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [sending, setSending] = React.useState(false);
  const [textMessage, setTextMessage] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [setSelectedUsers] = React.useState([]);
  const [selectedEmailUsers, setSelectedEmailUsers] = React.useState([]);
  const [errors, setErrors] = React.useState([]);
  const [uploadedImages, setUploadedImages] = React.useState([]);
  const [openProgress, setOpenProgress] = React.useState(false);
  const [progressModalStyle] = React.useState(getProgressModalStyle);

  function getProgressModalStyle() {
    const top = 50; //+ rand();
    const left = 50; // + rand();
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
      backgroundColor: "transparent",
      boxShadow: "none",
    };
  }

  useEffect(() => {
    GetUsers().then((response) => {
      setUsers(response);
      setEmailUsers(response.filter((x) => x.EmailConfirmed === true));
      setLoading(false);
    });
  }, []);

  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  };

  const convertContentToHTML = () => {
    let currentContentAsHTML = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    setConvertedContent(currentContentAsHTML);
  };

    const validateForm = () => {
    let temp = [];
    var formState = true;
    if (subject === "") {
      temp.Subject = "Please enter subject";
      formState = false;
    }

    if (type === "1") {
      if (selectedEmailUsers.length === 0) {
        temp.Users = "Please select a user";
        formState = false;
      }
      if (convertedContent == null) {
        temp.Message = "Please enter message";
        formState = false;
      }
    }

    if (type === "2") {
      if (textMessage.length === 0) {
        temp.Message = "Please enter message";
        formState = false;
      }
    }

    setErrors(temp);
    return formState;
  };

  const handleClick = async () => {
    setSending(true);
    setOpenProgress(true);

    var sendEmailData;
    var sendNotificationData;
    var body;

    setErrors([]);
    var formState = validateForm();

    if (!formState) {
      return;
    }
    if (type === "1") {
      //Email
      body = convertedContent;
      var updatedBody;
      try {
        updatedBody = await uploadImages(body);
      } catch (exception) {
        setSending(false);
        setOpenProgress(false);
        let temp = [];
        temp.SystemError = "System Error. Please try again.";
        setErrors(temp);

        return;
      }
      sendEmailData = {
        Body: updatedBody,
        Title: subject,
        Users: selectedEmailUsers,
      };
    } else if (type === "2") {
      body = textMessage;
      
      sendNotificationData = {
        body: body,
        title: subject,
        dataJson: "",
        icon: "",
        sent: true,
       
      };
    }
    var isSuccess = 0;
    if (type === "1") {
      await SendEmail(sendEmailData).then((response) => {
        if (response === "Email Sent Successfully") {
          isSuccess = 1;
          window.location = "/messages";
        }
      });
    } else if (type === "2") {
      console.log("sending....");
      await SendNotification(sendNotificationData).then((response) => {
        console.log("response");
        console.log(response);
        if (response === true) {
          window.location = "/messages";
        }
      });
    }
    setSending(false);
    setOpenProgress(false);
  };

  const handleChange = (event) => {
    setType(event.target.value);
  };

  const _uploadImageCallBack = (file) => {
    
    var uploadedImagesList = uploadedImages;

    const imageObject = {
      file: file,
      localSrc: URL.createObjectURL(file),
    };

    uploadedImagesList.push(imageObject);

    setUploadedImages(uploadedImagesList);

    const formData = new FormData();

    formData.append("myFile", file, "email.jpg");

    return new Promise((resolve, reject) => {
      resolve({ data: { link: imageObject.localSrc } });
    });
  };

  const uploadImages = async (body) => {
    var i;
    var updatedBody = body;
    for (i = 0; i < uploadedImages.length; i++) {
      var imageObject = uploadedImages[i];
      if (body.includes(imageObject.localSrc)) {
        const formData = new FormData();

        formData.append("myFile", imageObject.file, "email.jpg");

        var serverURL;
        try {
          serverURL = await UploadImageToServer(formData);
          updatedBody = updatedBody.replace(
            imageObject.localSrc,
            serverURL.data
          );
        } catch (exception) {
          console.log("exception: " + exception);
          throw exception;
        }
      }
    }
    return updatedBody;
  };

  return (
    <div className="mainDiv">
    <form className={classes.root} autoComplete="off" >
      <div>
        {/* <div className={classes.heading} >New Message</div> */}
        <div className="preview">
          {!loading && (
            <div style={{ textAlign: "left" }}>
              <FormControl
                fullWidth
                {...(errors["SystemError"] && {
                  error: true,
                })}
              >
                {errors["SystemError"] && (
                  <FormHelperText>{errors["SystemError"]}</FormHelperText>
                )}
              </FormControl>

              <FormControl component="fieldset">
                <FormLabel component="legend">Type</FormLabel>
                <RadioGroup
                  aria-label="gender"
                  name="type"
                  row
                  value={type}
                  onChange={handleChange}
                >
                  
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="Notification"
                  />
                </RadioGroup>
              </FormControl>
              <p />
              {type === "1" && (
                <FormControl
                  fullWidth
                  {...(errors["Users"] && {
                    error: true,
                  })}
                >
                  <Autocomplete
                    onChange={(event, value) => {
                      setSelectedEmailUsers(value);
                    }} // prints the selected value
                    multiple
                    id="tags-standard"
                    options={emailUsers}
                    getOptionLabel={(emailUsers) =>
                      emailUsers.FirstName + " " + emailUsers.LastName
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="To"
                        required={true}
                      />
                    )}
                  />
                  {errors["Users"] && (
                    <FormHelperText>{errors["Users"]}</FormHelperText>
                  )}
                </FormControl>
              )}
              {type === "2" && (
                <FormControl
                  fullWidth
                  {...(errors["Users"] && {
                    error: true,
                  })}
                >
                  <Autocomplete
                    onChange={(event, value) => {
                      setSelectedUsers(value);
                    }} // prints the selected value
                    multiple
                    id="tags-standard"
                    options={users}
                    getOptionLabel={(users) =>
                      users.FirstName + " " + users.LastName
                    }
                    renderInput={(params) => (
                      <TextField {...params} variant="standard" label="To" />
                    )}
                  />
                  {errors["Users"] && (
                    <FormHelperText>{errors["Users"]}</FormHelperText>
                  )}
                </FormControl>
              )}
              <p />
              <div>
                <TextField
                  label="Subject"
                  variant="outlined"
                  fullWidth
                  inputProps={{ maxLength: 20, required: true }}
                  onChange={(event) => {
                    setSubject(event.target.value);
                  }}
                  {...(errors["Subject"] && {
                    error: true,
                    helperText: errors["Subject"],
                  })}
                />
              </div>
              <p />
              <FormControl fullWidth className={classes.margin}>
                {type === "1" && (
                  <FormControl
                    fullWidth
                    {...(errors["Message"] && {
                      error: true,
                    })}
                  >
                    <div>
                      <Editor
                        editorState={editorState}
                        onEditorStateChange={handleEditorChange}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                        toolbar={{
                          image: {
                            uploadCallback: _uploadImageCallBack,
                            previewImage: true,
                            alt: { present: true, mandatory: false },
                            inputAccept:
                              "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                          },
                        }}
                      />
                    </div>
                    {errors["Message"] && (
                      <FormHelperText>{errors["Message"]}</FormHelperText>
                    )}
                  </FormControl>
                )}
                {type === "2" && (
                  <div>
                    <TextField
                      label="Message"
                      variant="outlined"
                      fullWidth
                      multiline
                      maxRows={6}
                      minRows={4}
                      onChange={(event) => {
                        setTextMessage(event.target.value);
                      }}
                      {...(errors["Message"] && {
                        error: true,
                        helperText: errors["Message"],
                      })}
                    />
                  </div>
                )}
              </FormControl>
              <p />
              <div style={{ textAlign: "right" }}>
                <Button
                  variant="contained"
                  onClick={(e) => {
                    handleClick();
                  }}
                  className=".button"
                  style={{
                    backgroundColor: "#77BBBB",
                    color: "white",
                    fontWeight: "bold",
                  }}
                  disabled={sending}
                >
                  {sending && (
                    <CircularProgress
                      size={24}
                      style={{
                        color: "white",
                      }}
                     
                    />
                  )}

                  {sending && <span>Sending...</span>}

                  {!sending && <span>Send</span>}
                </Button>
              </div>
            </div>
          )}
        </div>
        {loading && (
          <CircularProgress
            style={{
              color: "#77BBBB",
            }}
          />
        )}
      </div>
      {sending && (
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={openProgress}
        >
          <div style={progressModalStyle} className={classes.paper}></div>
        </Modal>
      )}
    </form>
    </div>
  );
};
export default New;
