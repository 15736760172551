export const callAPI = async (
  route,
  token,
  body,
  method = "POST",
  formData = null
) => {
  var url = "https://sportlapi.compass-dx.com/" + route;

  if (body != null) {
    body = JSON.stringify(body);
  }

  console.log("Calling endpoint: " + url);

  var config = {
    method: method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  if (body != null) {
    config.body = body;
  } else if (formData != null) {
    config = {
      method: method,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    };
    config.body = formData;
    console.log(config);
  }

  var Data = await fetch(url, config)
    .then(async (response) => {
      if (response.ok) {
        // console.log('Promise.resolve', Promise.resolve(response.json()));
        return Promise.resolve(response.json());
      }

      return Promise.resolve(response.json()).then((responseInJson) => {
        // This will end up in ERROR part
        return Promise.reject(responseInJson.Message);
      });
    })
    .then(function (result) {
      //console.log("API response ==>" + JSON.stringify(result));
      return result;
    })
    .catch(function (error) {
      console.log("error:" + JSON.stringify(error));
      throw error;
    });

  return Data;
};
