import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import { Grid, TextField } from "@material-ui/core";
import {
  addMerchants,
  updateMerchants,
  UploadImageToServer,
} from "../../API/service";
import { setSelectionRange } from "@testing-library/user-event/dist/utils";
import { type } from "@testing-library/user-event/dist/type";

export default function Edit(props) {
  const [open, setOpen] = React.useState(false);

  const [name, setName] = React.useState("");
  const [website, setWebsite] = React.useState("");
  const [contact, setContact] = React.useState("");
  const [code, setCode] = React.useState("");
  const [btnDisabled, setBtnDisabled] = React.useState(true);
  const [showAlert, setShowAlert] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(
    "An error has occurred. Please try again later."
  );
  const [btnLoading, setBtnLoading] = React.useState(false);
  const [image, setImage] = React.useState();
  const [imgFile, setImgFile] = React.useState();
  const [imageUpdated, setImageUpdated] = React.useState(false);
  const [logoUrl, setLogoUrl] = React.useState("");

  const handleOpen = () => {
    clearStateValues();
    setOpen(true);
  };

  const handleClose = () => {
    props.handleClose();
    setBtnDisabled(true);
    setOpen(false);
  };

  const clearStateValues = () => {
    setName("");
    setWebsite("");
    setContact("");
    setCode("");
    setBtnLoading(false);
    setShowAlert(false);
    setImageUpdated(false);
    setImage(null);
    setImgFile(null);
    setLogoUrl("");
  };

  const ALPHA = /^[a-zA-Z .]+$/;
  const num = /^[0-9]+$/;
  const web = /^[a-zA-Z .:/-/-0-9]+$/;

  useEffect(() => {
    if (props.data != null) {
      setName(props.data.name);
      setWebsite(props.data.website);
      setContact(props.data.contact);
      setCode(props.data.code);
      setBtnDisabled(false);
      setShowAlert(false);
      setBtnLoading(false);

      if (props.data.logo) {
        setLogoUrl(process.env.REACT_APP_API_ENDPOINT + "/" + props.data.logo);
      }

      setOpen(true);
    }
  }, [props.data]);

  useEffect(() => {
    const regex_Web =
      /([A-Za-z0-9._]+(\?([a-z0-9+*.=_&-]|%[0-9a-f][0-9a-f])*)?)/;
    if (
      regex_Web.test(website) &&
      name.length > 0 &&
      contact.length > 0 &&
      code.length === 4
    ) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  }, [name, website, contact, code]);

  const onAddButtonClick = async () => {
    const Data = {
      name: name,
      website: website,
      contact: contact,
      code: code,
    };

    setBtnLoading(true);
    setBtnDisabled(true);
    if (props.data == null) {
      console.log(Data);
      await addMerchants(Data)
        .then(async (response) => {
          console.log("response", response);

          if (imageUpdated) {
            await uploadImage(response.message.merchantId)
              .then((imgResponse) => {
                console.log("imgResponse");
                console.log(imgResponse);
                handleClose();
              })
              .catch((error) => {
                console.log("error uploading image: " + error);
                setErrorMessage(
                  "Error uploading image. Edit Merchant to upload image."
                );
                setShowAlert(true);
                setBtnLoading(false);
                setBtnDisabled(false);
              });
          } else {
            handleClose();
          }
        })
        .catch((error) => {
          console.log(error);

          setErrorMessage(error);
          setShowAlert(true);
          setBtnLoading(false);
          setBtnDisabled(false);
        });
    } else {
      await updateMerchants(props.data.merchantId, Data)
        .then(async (response) => {
          console.log(response);
          if (imageUpdated) {
            await uploadImage(response.message.merchantId)
              .then((imgResponse) => {
                console.log("imgResponse");
                console.log(imgResponse);

                handleClose();
              })
              .catch((error) => {
                setErrorMessage(
                  "Error uploading image. Edit Merchant to upload image."
                );
                setShowAlert(true);
                setBtnLoading(false);
                setBtnDisabled(false);
              });
          } else {
            handleClose();
          }
        })
        .catch((error) => {
          console.log(error);
          setErrorMessage(error);
          setShowAlert(true);
          setBtnLoading(false);
          setBtnDisabled(false);
        });
    }
  };

  const selectImage = async (e) => {
    var files = e.target.files;
    var mimeType = files[0].type;

    if (!mimeType.startsWith("image")) {
      setErrorMessage("Incorrect file type!");
      setShowAlert(true);
      return;
    } else {
      setErrorMessage("");
      setShowAlert(false);
    }
    setLogoUrl("");
    setImage(files[0]);

    setImage(URL.createObjectURL(files[0]));
    setImgFile(files[0]);
    setImageUpdated(true);
  };

  const uploadImage = async (merchantId) => {
    var formData = new FormData();
    formData.append("file", imgFile, imgFile.name);
    var response = await UploadImageToServer(formData, merchantId);
    console.log(response);
    return response;
  };

  return (
    <div>
      <div className="button">
        <Button
          variant="contained"
          onClick={handleOpen}
          size="large"
          color="primary"
        >
          Add Merchant
        </Button>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="boxstyle">
          <Grid container className="mod" spacing={2} alignItems="stretch">
            <Grid item xs={12} align="center">
              <h2>Merchants Details</h2>
            </Grid>

            <Grid item xs={12}>
              {showAlert && <Alert severity="error">{errorMessage}</Alert>}
            </Grid>
            <Grid item xs={12} align="center">
              <img
                src={logoUrl ? logoUrl : image}
                style={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "50%",
                }}
                alt={image?.name}
                loading="lazy"
              />
              <br />
              <input type="file" accept="image/*" onChange={selectImage} />
            </Grid>

            <Grid item xs={6}>
              <span className="modtext">Name</span>
              <TextField
                placeholder=""
                fullWidth
                required
                value={name}
                onChange={(event) => {
                  const value = event.target.value;

                  if (!ALPHA.test(value) && value.length > 0) {
                    event.preventDefault();
                  } else {
                    setName(value);
                  }
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <span className="modtext">Website</span>
              <TextField
                placeholder=""
                fullWidth
                required
                value={website}
                onChange={(event) => {
                  const value = event.target.value;

                  if (!web.test(value) && value.length > 0) {
                    event.preventDefault();
                  } else {
                    setWebsite(value);
                  }
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <span className="modtext">Phone Number</span>
              <TextField
                placeholder=""
                fullWidth
                required
                value={contact}
                onChange={(event) => {
                  const value = event.target.value;
                  if (!num.test(value) && value.length > 0) {
                    event.preventDefault();
                  } else {
                    setContact(value);
                  }
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <span className="modtext">Code</span>
              <TextField
                placeholder=""
                fullWidth
                // max
                required
                inputProps={{ maxLength: "4", minLength: "4" }}
                value={code}
                onChange={(event) => {
                  const value = event.target.value;
                  if (!num.test(value) && value.length > 0) {
                    event.preventDefault();
                  } else {
                    setCode(value);
                  }
                }}
              />
            </Grid>

            <Button
              style={{ marginTop: "10px" }}
              variant="contained"
              onClick={onAddButtonClick}
              disabled={btnDisabled}
            >
              {btnLoading && <CircularProgress size="1rem" />}
              Save
            </Button>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
