import React from "react";
import clsx from "clsx";
// import { styled, createTheme, ThemeProvider } from "@mui/system";
import {
  Drawer,
  CssBaseline,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Menu,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import StorefrontIcon from "@mui/icons-material/Storefront";
import DiscountIcon from "@mui/icons-material/Discount";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MessageIcon from "@mui/icons-material/Message";
import { Link } from "react-router-dom";
import classes from "./Header.css";

export default function PersistentDrawerLeft(props) {
  const [open, setOpen] = React.useState(false);
  const [auth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const itemsList = [
    {
      text: "Merchants",
      icon: <StorefrontIcon />,
      linkTo: "/merchants",
    },
    {
      text: "Coupons",
      icon: <DiscountIcon />,
      linkTo: "/coupons",
    },
    {
      text: "Messages",
      icon: <MessageIcon />,
      linkTo: "/messages",
    },
  ];

  return (
    <div className="root">
      <CssBaseline />
      <AppBar position="fixed" className="root">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx("menuButton", open && "hide")}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1 }}
            textAlign="center"
          >
            Sportl Admin Portal
          </Typography>
          {auth && (
            <div>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircleIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={openMenu}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose} component={Link} to="/login">
                  Logout
                </MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        PaperProps={{
          sx: {
            width: 250,
          },
        }}
        className={classes.drawer}
        anchor="left"
        open={open}
        handleClose={handleDrawerClose}
        onClose={handleDrawerClose}
        // classes={{
        //   paper: classes.drawerPaper,
        // }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          {itemsList.map((item, index) => {
            const { text, icon, linkTo } = item;
            return (
              <ListItem
                button
                key={text}
                component={Link}
                to={linkTo}
                onClick={handleDrawerClose}
              >
                {icon && <ListItemIcon>{icon}</ListItemIcon>}
                <ListItemText primary={text} />
              </ListItem>
            );
          })}
        </List>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
        role="presentation"
      >
        <div className={classes.drawerHeader} />
      </main>
    </div>
  );
}
