import { Outlet } from "react-router-dom";
import { Grid } from "@material-ui/core";

import HeaderLoggedOut from "../Header/HeaderLoggedOut";
import Footer from "../Footer/Footer";

export default function LoggedoutTemplate() {
  return (
    <Grid container>
      <Grid item xs={12}>
        <HeaderLoggedOut />
      </Grid>
      <Grid item xs={12} sx={{ marginTop: "100px" }}>
        <Outlet />
      </Grid>
      <Grid item xs={12} sx={{ justifyContent: "center", paddingTop: 6 }}>
        <Footer />
      </Grid>
    </Grid>
  );
}
