import React from "react";
import {
  CssBaseline,
  AppBar,
  Toolbar,
  Typography,
} from "@mui/material";

export default function HeaderLoggedOut(props) {
   return (
    <div className="root">
      <CssBaseline />
      <AppBar position="fixed" className="root">
        <Toolbar>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1 }}
            textAlign="center"
          >
            Sportl Admin Portal
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
}
