import { callAPI } from "./apiCalls";

export const GetMerchants = async () => {
  try {
    return await callAPI("Merchants", "", null, "GET");
  } catch (ex) {
    console.log(ex);
    return [];
  }
};

export const GetCoupons = async () => {
  try {
    return await callAPI("merchantcoupons", "", null, "GET");
  } catch (ex) {
    console.log(ex);
    return [];
  }
};

export const GetUsers = async () => {
  try {
    return await callAPI("users", "", null, "GET");
  } catch (ex) {
    console.log(ex);
    return [];
  }
};

export const GetNotificaitons = async () => {
  try {
    return await callAPI("notifications", "", null, "GET");
  } catch (ex) {
    console.log(ex);
    return [];
  }
};

export const addMerchants = async (AddMerchantsData) => {
  try {
    return await callAPI("merchants", "", AddMerchantsData, "POST");
  } catch (ex) {
    console.log(ex);
    return [];
  }
};

export const updateMerchants = async (id, updMerchantsData) => {
  try {
    return await callAPI("merchants/" + id, "", updMerchantsData);
  } catch (ex) {
    console.log(ex);
    return [];
  }
};

export const deleteMerchant = async (id) => {
  try {
    return await callAPI("merchants/" + id, "", null, "DELETE");
  } catch (ex) {
    console.log(ex);
    throw ex;
  }
};

export const addCoupons = async (addCouponsData) => {
  try {
    console.log(addCouponsData);
    return await callAPI("merchantcoupons", "", addCouponsData, "POST");
  } catch (ex) {
    console.log(ex);
    return [];
  }
};

export const updateCoupon = async (id, updCouponData) => {
  try {
    console.log("updCouponData");
    console.log(updCouponData);
    return await callAPI("merchantcoupons/" + id, "", updCouponData);
  } catch (ex) {
    console.log(ex);
    return [];
  }
};

export const deleteCoupon = async (id) => {
  try {
    return await callAPI("merchantcoupons/" + id, "", null, "DELETE");
  } catch (ex) {
    console.log(ex);
    throw ex;
  }
};

export const SendEmail = async (sendEmailData) => {
  try {
    return await callAPI(
      "Notifications/AddEmailNotification",
      "",
      sendEmailData,
      "POST"
    );
  } catch (ex) {
    console.log(ex);
    return [];
  }
};

export const SendNotification = async (sendNotificationData) => {
  try {
    return await callAPI("notifications", "", sendNotificationData, "POST");
  } catch (ex) {
    console.log(ex);
    return [];
  }
};

// export const SendNotification = async (sendNotificationData) => {
//   try {
//     return await callAPI(
//       "Notifications/AddNotification",
//       "",
//       sendNotificationData,
//       "POST"
//     );
//   } catch (ex) {
//     console.log(ex);
//     return [];
//   }
// };

export const ResendNotification = async (resendNotificationData) => {
  try {
    return await callAPI(
      "Notifications/Resend",
      "",
      resendNotificationData,
      "POST"
    );
  } catch (ex) {
    console.log(ex);
    return [];
  }
};

export const GetNotificationUsers = async (notificationId, type) => {
  try {
    return await callAPI(
      "Notifications/GetUsers/" + notificationId + "/" + type,
      "",
      null,
      "GET"
    );
  } catch (ex) {
    console.log(ex);
    return [];
  }
};

export const UploadImageToServer = async (formData, merchantID) => {
  // var url =
  //   process.env.REACT_APP_API_ENDPOINT + "merchants/uploadlogo/" + merchantID;

  try {
    for (var key of formData.entries()) {
      console.log(key[0] + ", " + key[1]);
    }
    return await callAPI(
      "merchants/uploadlogo/" + merchantID,
      "",
      null,
      "POST",
      formData
    );
  } catch (exception) {
    console.log(exception);
  }
};
