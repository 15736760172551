import { DataGrid } from "@mui/x-data-grid";
import { GetCoupons, deleteCoupon } from "../../API/service.jsx";
import React, { useEffect } from "react";
//import CircularProgress from "@mui/material/CircularProgress";
// import Button from "@mui/material/Button";
// import Alert from "@mui/material/Alert";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Alert,
  Button,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { Close } from "@mui/icons-material";

import Edit from "./edit";

export default function Index() {
  function getMerchantName(params) {
    return params.row.merchant?.name;
  }

  const columns = [
    {
      field: "merchant.name",
      valueGetter: getMerchantName,
      headerName: "Merchant Name",
      minWidth: 150,
      flex: 2,
      headerAlign: "center",
      align: "left",
    },
    {
      field: "title",
      headerName: "Title",
      minWidth: 300,
      flex: 3,
      headerAlign: "center",
      align: "left",
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 350,
      flex: 4,
      headerAlign: "center",
      align: "left",
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "left",
      renderCell: (row) => (
        <Button
          variant="contained"
          onClick={(e) => {
            e.stopPropagation();
            setCouponToDelete(row.row);
            setShowDialog(true);
            setBtnDeleteDisabled(true);
            setBtnDeleteLoading(true);
          }}
          disabled={btnDeleteDisabled}
        >
          {btnDeleteLoading && <CircularProgress size="1rem" />}
          Delete
        </Button>
      ),
    },
  ];

  const [coupons, setCoupons] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [selectedCoupon, setSelectedCoupon] = React.useState(null);
  const [btnDeleteDisabled, setBtnDeleteDisabled] = React.useState(false);
  const [btnDeleteLoading, setBtnDeleteLoading] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [msgType, setMsgType] = React.useState("");
  const [showDialog, setShowDialog] = React.useState(false);
  const [couponToDelete, setCouponToDelete] = React.useState(null);
  const [btnYesClick, setBtnYesClick] = React.useState(false);

  const onYesClick = async (row) => {
    setBtnYesClick(true);
    await deleteCoupon(couponToDelete.couponsId)
      .then((response) => {
        console.log(response);
        setMessage("Coupon deleted successfully.");
        setShowAlert(true);
        setMsgType("success");
        getCoupons();
        setBtnYesClick(false);
        setShowDialog(false);
        setBtnDeleteDisabled(false);
        setBtnDeleteLoading(false);
      })
      .catch((error) => {
        console.log("error: " + error);
        setMessage("Error in deleting coupon.");
        setShowAlert(true);
        setMsgType("error");
        setBtnYesClick(false);
        setShowDialog(false);
        setBtnDeleteDisabled(false);
        setBtnDeleteLoading(false);
      });
  };

  const getCoupons = async () => {
    setLoading(true);
    await GetCoupons()
      .then((response) => {
        console.log(response);
        if (response.message) {
          setCoupons(response.message);
        } else {
          setCoupons([]);
        }
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    setLoading(true);
    GetCoupons()
      .then((response) => {
        console.log(response);
        if (response.message) {
          setCoupons(response.message);
        } else {
          setCoupons([]);
        }
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const onRowSelection = (row) => {
    setSelectedCoupon(row);
  };

  const onClose = () => {
    setSelectedCoupon(null);
    getCoupons();
  };

  return (
    <div className="mainDiv">
      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
        maxWidth="xs"
        open={showDialog}
      >
        <DialogTitle>Delete Merchant</DialogTitle>
        <DialogContent dividers>
          <Typography variant="subtitle1" component="div">
            Are you sure you want to delete coupon{" "}
            <b>{couponToDelete?.title}</b>?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={onYesClick}>
            {btnYesClick && <CircularProgress size="1rem" />}
            <b>Yes</b>
          </Button>
          <Button
            onClick={(e) => {
              setShowDialog(false);
              setBtnDeleteDisabled(false);
              setBtnDeleteLoading(false);
            }}
          >
            <Typography
              style={{ color: "black", fontWeight: "bold", fontSize: "14px" }}
            >
              No
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
      <Edit data={selectedCoupon} handleClose={onClose} />
      <div className="alert">
        {showAlert && (
          <Alert
            severity={msgType}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setShowAlert(false);
                }}
              >
                <Close fontSize="inherit" />
              </IconButton>
            }
          >
            {message}
          </Alert>
        )}
      </div>
      <div className="grid">
        <DataGrid
          rows={coupons}
          loading={loading}
          getRowId={(row) => row.couponsId}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[5, 10, 50, 100]}
          onRowClick={(rowData) => onRowSelection(rowData.row)}
        />
      </div>
    </div>
  );
}
