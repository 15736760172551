import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Merchants from "./components/merchants/index";
import Coupons from "./components/coupons/index";
import MerchEdit from "./components/merchants/edit";
import CouponEdit from "./components/coupons/edit";
import LoginForm from "./components/login/index";
import MessageList from "./components/messages/index";
import MessageNew from "./components/messages/new";

import "./App.css";
import "./components/Header/Header.css";
import LoggedoutTemplate from "./components/templates/loggedouttemplate";
import LoggedInTemplate from "./components/templates/loggedintemplate";

import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#49aef4",
      contrastText: "#fff",
    },
    // text: {
    //   primary: "#fff",
    // },
  },
});

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route element={<LoggedoutTemplate />}>
              <Route exact path="/login" element={<LoginForm />} />
              <Route exact path="/" element={<LoginForm />} />
            </Route>

            <Route element={<LoggedInTemplate />}>
              <Route exact path="/merchants" element={<Merchants />} />
              <Route exact path="/coupons" element={<Coupons />} />
              <Route exact path="/merchants/edit" element={<MerchEdit />} />
              <Route exact path="/coupons/edit" element={<CouponEdit />} />
              <Route exact path="/messages" element={<MessageList />} />
              <Route exact path="/messages/new" element={<MessageNew />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
