import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import {
  GetNotificaitons,
  ResendNotification,
  GetNotificationUsers,
} from "../../API/service.jsx";
import Button from "@mui/material/Button";
import {
  CircularProgress,
  Modal,
  Typography,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@mui/material/Autocomplete";

import { EditorState } from "draft-js";
// import { convertToHTML } from "draft-convert";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import moment from "moment";
import "./messages.css";

export default function Index() {
  const handleResend = async (e, rowParams) => {
    e.stopPropagation();
    setResending(true);
    setOpenProgress(true);

    const Data = {
      Id: rowParams.getValue("Id"),
      Type: rowParams.getValue("Type"),
    };

    await ResendNotification(Data).then((response) => {
      if (response) {
      }
    });

    setResending(false);
    setOpenProgress(false);
  };

  function getCreationDate(params) {
    return moment(`${params.getValue("CreationDate")}`).format("MMM DD, yyyy");
  }

  const columns = [
    {
      field: "FormattedCreationDate",
      flex: 0.5,
      valueGetter: getCreationDate,
      headerName: "Creation Date",  
    },
    { 
      field: "Title", 
      headerName: "Title", 
      flex: 1 
    },
    { field: "Body", 
    headerName: "Body", 
    headerAlign: "center",
    align: "center",
    flex: 2 
    },
    {
      field: "ResendBtn",
      headerName: " ",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <div>
            <Button
              variant="contained"
              disabled={resending}
              style={{
                backgroundColor: "#77BBBB",
                color: "white",
                fontWeight: "bold",
              }}
              onClick={(e) => {
                handleResend(e, params);
              }}
            >
              Resend
            </Button>
          </div>
        );
      },
      width: "15%",
    },
  ];


  function getModalStyle() {
    const top = 50; 
    const left = 50; 
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  function getProgressModalStyle() {
    const top = 50; 
    const left = 50; 
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
      backgroundColor: "transparent",
      boxShadow: "none",
    };
  }

  const [open, setOpen] = React.useState(false);
  const [openProgress, setOpenProgress] = React.useState(false);
  const [selectedMessage, setSelectedMessage] = React.useState(null);
  const [modalStyle] = React.useState(getModalStyle);
  const [progressModalStyle] = React.useState(getProgressModalStyle);
  const [loading, setLoading] = React.useState(true);
  const [loadingUsers, setLoadingUsers] = React.useState(true);
  const [resending, setResending] = React.useState(false);
  const [notificaitons, setNotifications] = React.useState([]);
  const [notificationUsers, setNotificationUsers] = React.useState([]);
  const [setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [convertedContent, setConvertedContent] = useState(null);

  const createMarkup = (html) => {
    return {
      __html: html,
    };
  };

  // const handleEditorChange = (state) => {
  //   setEditorState(state);
  //   convertContentToHTML();
  // };

  // const convertContentToHTML = () => {
  //   let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
  //   setConvertedContent(currentContentAsHTML);
  // };

  useEffect(() => {
    GetNotificaitons()
      .then((response) => {
        setNotifications(response);
        setLoading(false);
        console.log("response", response);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = (row) => {
    setSelectedMessage(row);
    handleOpen();
    GetNotificationUserList(row.Id, row.Type);
  };

  const GetNotificationUserList = async (id, type) => {
    setLoadingUsers(true);
    await GetNotificationUsers(id, type).then((response) => {
      console.log("response", response);
      setNotificationUsers(response["UserList"]);
      if (type === "E") {
        setEditorState(response["Body"]);
        setConvertedContent(response["Body"]);
      }
    });
    setLoadingUsers(false);
  };

  return (
    <div className="mainDiv">
      <div>
        <div
          style={{
            textAlign: "right",
          }}
        >
          <Link to="/messages/new" style={{ textDecoration: "none" }}>
            <Button variant="contained" color="primary">
              New
            </Button>
          </Link>
        </div>

        <div className="grid">
          <Grid container justify="center">
            <div style={{ height: 400, width: 1250 }}>
              <DataGrid
                rows={notificaitons}
                columns={columns}
                pageSize={5}
                getRowId={(row) => row.Id + row.Type}
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                hideFooterSelectedRowCount={true}
                loading={loading}
                onRowClick={(rowData) => handleClick(rowData.row)}
              />
            </div>
          </Grid>
        </div>
      </div>

      {resending && (
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={openProgress}
        >
          <div style={progressModalStyle}>
            {openProgress && (
              <div style={{ textAlign: "center" }}>
                <CircularProgress
                  style={{
                    color: "#77BBBB",
                  }}
                />
              </div>
            )}
          </div>
        </Modal>
      )}
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <div style={modalStyle}>
          {open && (
            <div>
              {!loadingUsers && (
                <div>
                  <div style={{ display: "flex", paddingRight: "0px" }}>
                    <Typography
                      variant="h6"
                      component="div"
                      style={{ flexGrow: 1 }}
                    >
                      Message Details
                    </Typography>
                  </div>
                  <p>
                    <b>Type </b>
                    {selectedMessage.Type === "E" && <div>Email</div>}
                    {selectedMessage.Type === "N" && <div>Notification</div>}
                  </p>
                  <p>
                    <b>Title </b>
                    {selectedMessage.Title}
                  </p>
                  <p>
                    <b>Text </b>

                    {selectedMessage.Type === "E" && (
                      <div
                        dangerouslySetInnerHTML={createMarkup(convertedContent)}
                      ></div>
                    )}
                    {selectedMessage.Type === "N" && selectedMessage.Body}
                  </p>
                  <p>
                    <b>Sent to Users </b>
                    {notificationUsers && (
                      <Autocomplete
                        disabled
                        multiple
                        id="tags-standard"
                        options={notificationUsers}
                        value={notificationUsers}
                        getOptionLabel={(notificationUsers) =>
                          notificationUsers.FirstName +
                          " " +
                          notificationUsers.LastName
                        }
                        renderInput={(params) => (
                          <TextField {...params} variant="standard" />
                        )}
                      />
                    )}
                    {!notificationUsers && "Counldn't find users."}
                  </p>
                  <Grid justify="center" xs={12}>
                    <Button
                      variant="contained"
                      aria-label="close"
                      onClick={handleClose}
                        style={{
                        backgroundColor: "#77BBBB",
                        color: "white",
                        fontWeight: "bold",
                      }}
                    >
                      <b>Close</b>
                    </Button>
                  </Grid>
                </div>
              )}
              {loadingUsers && (
                <div style={{ textAlign: "center" }}>
                  <CircularProgress
                    style={{
                      color: "#77BBBB",
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}
