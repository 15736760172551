import { DataGrid } from "@mui/x-data-grid";
import { GetMerchants, deleteMerchant } from "../../API/service.jsx";
import React, { useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Alert,
  Button,
  IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";

import Edit from "./edit";

import "./merchants.css";

export default function Index() {
  const columns = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 150,
      flex: 2,
      headerAlign: "center",
      align: "left",
    },
    {
      field: "website",
      headerName: "Website",
      minWidth: 250,
      flex: 3,
      headerAlign: "center",
      align: "left",
    },
    {
      field: "contact",
      headerName: "Contact",
      minWidth: 150,
      flex: 2,
      headerAlign: "center",
      align: "left",
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "left",
      renderCell: (row) => (
        <Button
          variant="contained"
          onClick={(e) => {
            e.stopPropagation();
            setMerchantToDelete(row.row);
            setShowDialog(true);
            setBtnDeleteDisabled(true);
            setBtnDeleteLoading(true);
          }}
          disabled={btnDeleteDisabled}
        >
          {btnDeleteLoading && <CircularProgress size="1rem" />}
          Delete
        </Button>
      ),
    },
  ];

  const [merchants, setMerchants] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [selectedMerchant, setSelectedMerchant] = React.useState(null);
  const [btnDeleteDisabled, setBtnDeleteDisabled] = React.useState(false);
  const [btnDeleteLoading, setBtnDeleteLoading] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [msgType, setMsgType] = React.useState("");
  const [showDialog, setShowDialog] = React.useState(false);
  const [merchantToDelete, setMerchantToDelete] = React.useState(null);
  const [btnYesClick, setBtnYesClick] = React.useState(false);

  const onYesClick = async () => {
    setBtnYesClick(true);
    await deleteMerchant(merchantToDelete.merchantId)
      .then((response) => {
        setMessage("Merchant deleted successfully.");
        setShowAlert(true);
        setMsgType("success");
        getMerchants();
        setBtnYesClick(false);
        setShowDialog(false);
        setBtnDeleteDisabled(false);
        setBtnDeleteLoading(false);
      })
      .catch((error) => {
        setMessage("Error in deleting merchant.");
        setShowAlert(true);
        setMsgType("error");
        setBtnYesClick(false);
        setShowDialog(false);
        setBtnDeleteDisabled(false);
        setBtnDeleteLoading(false);
      });
  };

  const getMerchants = async () => {
    setLoading(true);
    await GetMerchants()
      .then((response) => {
        console.log(response);
        if (response.message) {
          setMerchants(response.message);
          setLoading(false);
        } else {
          setMerchants([]);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    GetMerchants()
      .then((response) => {
        console.log("response", response);
        if (response.message) {
          setMerchants(response.message);
          setLoading(false);
        } else {
          setMerchants([]);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const onRowSelection = (row) => {
    setSelectedMerchant(row);
  };

  const onClose = () => {
    setSelectedMerchant(null);
    getMerchants();
  };

  return (
    <div className="mainDiv">
      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
        maxWidth="xs"
        open={showDialog}
      >
        <DialogTitle>Delete Merchant</DialogTitle>
        <DialogContent dividers>
          <Typography variant="subtitle1" component="div">
            Are you sure you want to delete merchant{" "}
            <b>{merchantToDelete?.name}</b>?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={onYesClick}>
            {btnYesClick && <CircularProgress size="1rem" />}
            <b>Yes</b>
          </Button>
          <Button
            onClick={(e) => {
              setShowDialog(false);
              setBtnDeleteDisabled(false);
              setBtnDeleteLoading(false);
            }}
          >
            <Typography
              style={{ color: "black", fontWeight: "bold", fontSize: "14px" }}
            >
              No
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
      <Edit data={selectedMerchant} handleClose={onClose} />
      <div className="alert">
        {showAlert && (
          <Alert
            severity={msgType}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setShowAlert(false);
                }}
              >
                <Close fontSize="inherit" />
              </IconButton>
            }
          >
            {message}
          </Alert>
        )}
      </div>
      <div className="grid">
        <DataGrid
          rows={merchants}
          getRowId={(row) => row.merchantId}
          columns={columns}
          loading={loading}
          pageSize={10}
          rowsPerPageOptions={[5, 10, 50, 100]}
          onRowClick={(rowData) => onRowSelection(rowData.row)}
        />
      </div>
    </div>
  );
}
