import "./footer.css";

export default function Footer() {
  const footer = new Date().getUTCFullYear() + " - Sportl";
  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <p />
        {<div dangerouslySetInnerHTML={{ __html: footer }}></div>}
      </div>
    </div>
  );
}
